<template>
  <ItemList
    :items="paymentForms"
    :is-loading="isLoadingList"
    no-data-text="Nenhuma Forma de pagamento encontrada"
    label="Forma de pagamento"
    @addItem="addPaymentForm"
    @removeItem="removePaymentForm"
  />
</template>

  <script>
  export default {
    components: {
      ItemList: () => import('./ItemList.vue'),
    },
    data: () => ({
      paymentForms: [],
      isLoadingList: false,
    }),
    mounted() {
      if (this.paymentForms.length === 0) {
        this.getSegments()
      }
    },
    methods: {
      getSegments() {
        this.isLoadingList = true
        this.$api
          .get('budget/paymentforms')
          .then((res) => {
            this.paymentForms = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoadingList = false
          })
      },
      addPaymentForm(value) {
        this.$api
          .post('budget/paymentforms/store', { name: value })
          .then((res) => {
            this.paymentForms.push(res.data.data)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      removePaymentForm(item) {
        this.$api
          .post('budget/paymentforms/delete', item)
          .then(() => {
            let idx = this.paymentForms.indexOf(item)
            this.paymentForms.splice(idx, 1)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
  </script>